<template>
  <div class="home-container">
    <div class="has-text-centered title-section is-marginless">
      <h5 class="title has-text-danger">{{langDisplayName}}</h5>
    </div>
    <div class="has-text-centered">
      <div class="field is-narrow">
        <div class="select is-rounded is-small has-text-weight-bold version-dropdown is-success">
          <select v-model="versionIndex">
            <option v-for="(version, index) in versions" v-bind:value="index" v-bind:key="version">
              {{version}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="box has-background-white-ter no-border-print shared-error" v-if="doodleForm.errorMessage">
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    </div>
    <div class="box has-background-primary no-border-print">
      <div id="terminal" class="terminal"></div>
    </div>
    <CommentBox class="is-hidden-print"/>
  </div>
</template>

<script>
import CommentBox from '../components/Utils/CommentsBox'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import { SERVER_ERROR } from '../assets/javascript/constants'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'

export default {
  name: 'terminal',
  components: { DoodleMessage, CommentBox },
  mixins: [formMixin],
  data: function () {
    return {
      ideMeta: {},
      versionIndex: 0,
      term: null
    }
  },
  mounted () {
    loadScriptInBody.loadScriptInBody('https://togetherjs.com/togetherjs-min.js')

    window.onbeforeunload = function () {
      return 'Are you sure you want to move from this IDE?'
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      loadScriptInBody.loadScriptInBody('/assets/javascript/jquery-terminal.js')
        .then(() => {
          if (vm.term) {
            vm.term.reset()
          }

          $('#terminal').terminal(vm.process, {
            prompt: '>',
            name: 'jdoodle-terminal',
            greetings: 'Welcome to JDoodle - online ' + vm.language + ' Terminal, Starting ' + vm.language + ' Terminal, Please wait...',
            onInit: vm.initTerminal
          })
        })
      vm.ideMeta = to.meta
    })
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (window.confirm('Do you want to leave ' + this.ideMeta.langDisplayName + ' IDE?')) {
      next()
    } else {
      next(false)
    }
  },
  computed: {
    langDisplayName () {
      return this.ideMeta.langDisplayName
    },
    language () {
      return this.ideMeta.terminal
    },
    versions () {
      return this.ideMeta.versions
    }
  },
  methods: {
    initTerminal (term) {
      this.term = term
      this.executeAPI({
        url: '/api/doodle/initTerminal',
        data: {
          lang: this.language
        },
        method: 'post',
        successAction: (data) => {
          term.echo(data.message)
        },
        failureAction: () => {
          term.echo(SERVER_ERROR)
        },
        markCompleted: true,
        form: this.doodleForm
      })
    },
    setTerminal (language) {
      this.language = language
      this.isTerminal = true
    },
    process (command, term) {
      let startTime
      if (window.performance) {
        startTime = performance.now()
      }

      $.ajax({
        url: '/api/doodle/executeInTerminal',
        method: 'post',
        data: {
          lang: this.language,
          command: command
        }
      }).done((data, textStatus, jqXHR) => {
        term.echo(data.message)
      }).fail((jqXHR, textStatus, errorThrown) => {
        window.jda.exception(errorThrown)
      }).always(() => {
        if (window.performance) {
          let execTime = performance.now() - startTime
          window.ga('send', {
            hitType: 'timing',
            timingCategory: 'execute',
            timingVar: this.language,
            timingValue: execTime
          })
        }
      })

      window.jda.pageEvent(window.jda.CATEGORY.TERMINAL, 'execute', this.language)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/style/jquery-terminal.css";

  .version-dropdown {
    margin-bottom: 0.5em;
  }

  .terminal {
    font-family: Monaco, Consolas, "Lucida Console", monospace;;
    font-size: 12px;
    font-weight: 900;
    border: 1px solid #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: -5px -5px 5px 5px #888;
    -webkit-box-shadow: -5px -5px 5px 5px #888;
    box-shadow: -1px -1px 2px 2px rgb(228, 228, 228);
    height: 700px;
    margin: auto;
    overflow : auto;
  }

  .terminal-actions {
    margin-top:20px;
  }

  .code-terminal {
    padding:0;
  }
</style>
